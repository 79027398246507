import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PostComponent from '../components/PostComponent';
import Paginate from '../components/Paginate';
import { Helmet } from "react-helmet";

class BlogPostList extends Component {
    render() {
        const postList = this.props.data.allMarkdownRemark;
        const blogHeader = this.props.data.site.siteMetadata.blog;
        const { siteMetadata } = this.props.data.site;
        const { count, limit, skip } = this.props.pageContext;
        return (
          <Layout  newsletter="true" about="true">
                <Helmet>
                    <title>آخرین خبرها | فیلترشکن‌ها</title>
                    <link rel="canonical" href={`https://filtershekanha.com/news`} />
                    <meta name="description" content={`${blogHeader.description || siteMetadata.main.description }`} />
                    <meta name="keywords" content={`${blogHeader.keyword || siteMetadata.main.keyword }`} />
                    <meta name="twitter:title" content="آخرین خبرها | فیلترشکن‌ها" />
                    <meta name="twitter:description" content={`${blogHeader.description}`} />
                    <meta name="twitter:image" content={`${siteMetadata.main.cover }`} />
                    <meta name="twitter:url" content={`https://filtershekanha.com/news`} />
                    <meta property="og:url" content={`https://filtershekanha.com/news`} />
                    <meta property="og:image" content={`${siteMetadata.main.cover }`} />               
                    <meta property="og:title" content="آخرین خبرها | فیلترشکن‌ها" />
                    <meta property="og:description" content={`${blogHeader.description || siteMetadata.main.description }`} />
                </Helmet> 
                <div className="row my-md-5 py-md-5"></div>
                <div className="row justify-content-center my-5 dir-rtl">
                    <div className="col-md-8 col-12 my-3 text-center">
                        <h1 className="text-center"><span className="bg-title px-4">{blogHeader.title}</span></h1>
                    </div>
                    <div className="col-md-8 col-12 my-4">
                        <p className="text-center">{blogHeader.description}</p>
                    </div>
                </div>
                <div className="row justify-content-center dir-rtl">
                    { postList.edges.map(({ node }, i) => (
                        <PostComponent post={node} key={i} />
                    ))}
                </div>
                <Paginate count={count} limit={limit} skip={skip} />
                <div className="row my-md-4"></div>
          </Layout>
      )
    }
}

export default BlogPostList;

export const listQuery = graphql`
    query blogListQuery($skip: Int!, $limit: Int!) {  
        site {
            siteMetadata {
                main {
                    title
                    description
                    image
                    cover
                    keyword
                }                
                blog {
                    title
                    description
                    keyword
                }
            }
        },
        allMarkdownRemark(      
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit      
            skip: $skip   
            filter: { frontmatter: {posttype: {eq: "blog"} } } 
        ) {
            edges {
                node {
                    fields{
                        slug
                    }
                    frontmatter {
                        date
                        title
                        description
                    }
                }
            }
        }
    }
`
