import React, { Component } from 'react';
import { Link } from 'gatsby';
import { persianDate } from '../utils/helper';

class PostComponent extends Component {
    render() {
        const { post } = this.props;
        const shamsiDate = persianDate(post.frontmatter.date);
        return (
            <Link to={post.fields.slug} className="col-lg-5 col-12 my-3 row-eq-height">
                <div className="row justify-content-center post-component py-5">
                    <div className="col-md-10 col-12 py-5">
                        <h6 className="dir-rtl text-center text-secondary mb-5"> {shamsiDate} </h6>
                        <h3 className="text-center c-dark mb-5 t"> {post.frontmatter.title} </h3>
                        <p className="text-justify text-dark"> { `${post.frontmatter.description}`.substring(0, 130)}...</p>
                    </div>
                </div>
            </Link>
        )
    }
}

export default PostComponent;